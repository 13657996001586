import { useMachine } from "@xstate/react";
import React, { useEffect } from "react";
import { QuizPlayerContext, quizPlayerStateMachine } from "@digischool/quiz";

// GraphQl types
import {
  Answer,
  BookmarkStatus,
  BookmarkType,
  Exercise,
  Question,
  Quiz,
  useQuizDoneAndTimePostMutation,
  useQuizProgressPostMutation,
} from "../../graphql";

// Material
import {
  Theme,
  Box,
  Button,
  CircularProgress,
  Typography,
  Container,
  Paper,
  LinearProgress,
  Dialog,
  Card,
} from "@material-ui/core";

// Style
import { makeStyles, createStyles } from "@material-ui/styles";
import "./Quiz.scss";
import { colorCAC } from "../../styles/color";

// Images
import fabNext from "./assets/fab-next.svg";
import fabValidate from "./assets/fab-validate.svg";
import fabExplain from "./assets/fab-explain.svg";
import fabValidateDisabled from "./assets/fab-validate-disabled.svg";
import icGoodAnswer from "./assets/ic-good-answer.svg";
import icBadAnswer from "./assets/ic-bad-answer.svg";
import { getLastBookmark } from "../../services/activity.service";
import { useSnackbar } from "notistack";
import { getTimestamp } from "../../helpers/date.helper";
import { addToCurrentYearTime } from "../../store/currentYearTime/actions";
import { useDispatch } from "react-redux";
import { getUserId } from "../../services/user.service";
import { Type } from "../../models/activity.model";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      minHeight: "100%",
    },
    quizContent: {
      padding: "0 10px 75px 10px",
    },
    btn: {
      outline: "none",
      minWidth: "auto",
      boxSizing: "border-box",
      width: "56px",
      height: "56px",
      borderRadius: "28px",
      border: "none",
      padding: "12px 0 0 0",
    },
    btnAction: {
      width: "56px",
      height: "70px",
      border: "none",
    },
    btnNext: {
      background: `url(${fabNext})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    btnValidate: {
      background: `url(${fabValidate})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    btnValidateDisabled: {
      background: `url(${fabValidateDisabled})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    btnExplanations: {
      background: `url(${fabExplain})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    progress: {
      width: "250px",
      height: 6,
      borderRadius: "20px",
      backgroundColor: theme.palette.grey[200],
      marginBottom: "1px",
    },
    bar: {
      backgroundColor: theme.palette.primary.main,
    },
    label: {
      width: "250px",
      fontSize: 14,
      marginBottom: "7px",
      letterSpacing: "0.3px",
      color: theme.palette.text.primary,
      fontWeight: "normal",
    },
    explanationModal: {
      zIndex: "2100!important" as any,
      padding: "20px",
    },
    explanationContent: {
      padding: "20px",
    },
    explanationTitle: {
      fontWeight: 800,
      fontSize: 24,
      textAlign: "center",
      marginBottom: theme.spacing(2),
      color: "#79a611",
    },
    explanationBtn: {
      margin: "16px 0 5px 0",
      color: "white",
    },
    bilan: {
      background: "#f1f4f5",
      textAlign: "center",
    },

    title: {
      fontSize: "15vw",
      fontWeight: 800,
      color: "#ebedee",
      margin: "0 auto",
    },
    subTitle: {
      fontSize: 28,
      fontWeight: 900,
      color: "#18202f",
      marginTop: -55,
    },
    correctionScore: {
      fontSize: 57.9,
      lineHeight: 1.2,
    },
    ratio: {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    separatorScore: {
      width: 67.9,
      height: 21.1,
      background: "url('./assets/spacer-note.svg') no-repeat",
      margin: "auto",
    },
    correctionComment: {
      fontSize: 20,
      lineHeight: 1.1,
      textAlign: "center",
      color: "#18202f",
    },
    groupButton: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      boxSizing: "border-box",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    button: {
      width: 200,
      fontWeight: 700,
    },
    buttonRetry: {
      color: "white",
    },
    buttonLeave: {
      backgroundColor: "white",
    },
  })
);

interface AnswerCardProps {
  text: string;
  selected: boolean;
  correction?: boolean;
  onClick: () => void;
}

const AnswerCard: React.FC<AnswerCardProps> = ({
  text,
  selected,
  correction,
  onClick,
}) => (
  <Card
    onClick={onClick}
    className={`answer ${selected ? "selected" : ""} ${
      correction ? "valid" : ""
    } ${correction === false ? "invalid" : ""}`}
  >
    <Typography className="typography">{text}</Typography>
    {correction === true && !selected && <img src={icGoodAnswer} alt="" />}
    {correction === false && selected && <img src={icBadAnswer} alt="" />}
  </Card>
);

const loadSavedQuiz = (id: string): QuizPlayerContext | undefined => {
  const data = window.localStorage.getItem(id);
  if (data) {
    return JSON.parse(data);
  }
  return;
};

const saveQuiz = (
  id: string,
  context: QuizPlayerContext,
  isStarter: boolean
): void => {
  window.localStorage.setItem(
    id + (isStarter ? "_starter" : ""),
    JSON.stringify(context)
  );
};

const deleteSavedQuiz = (id: string, isStarter: boolean): void => {
  window.localStorage.removeItem(id + (isStarter ? "_starter" : ""));
};

export interface QuizPlayerProps {
  data: Quiz;
  isStarter: boolean;
  isExpired: boolean;
  isDone: boolean;
  onQuizEnd: (score: number) => void;
  onExerciseChange: () => void;
}

export const QuizPlayer: React.FC<QuizPlayerProps> = ({
  data,
  isStarter,
  isExpired,
  isDone,
  onExerciseChange,
  onQuizEnd,
}) => {
  const [state, send] = useMachine(quizPlayerStateMachine);
  /** classes */
  const classes = useStyles();
  const type = data.tags && data.tags.length ? data.tags[0] : "";
  /** GraphQl */
  const [updateBookmark, { error }] = useQuizProgressPostMutation();
  const [updateBookmarkAndTime] = useQuizDoneAndTimePostMutation();
  /** use snackbar */
  const { enqueueSnackbar } = useSnackbar();
  /** Dispatch */
  const dispatch = useDispatch();

  useEffect(() => {
    onExerciseChange();
    const savedQuiz = loadSavedQuiz(data._id);
    send({
      type: "START",
      context: savedQuiz
        ? savedQuiz
        : {
            exerciseList: data.exerciseListData as any,
            muted: true,
            showAnswer: true,
            startScore: data.startScore ?? 0,
            timeout: 0,
            seriousFault: 0,
            maximumSeriousFault: 0,
          },
    });
  }, []);

  const currentExercise = React.useMemo(
    () => state.context.exerciseList[state.context.currentExercise],
    [state.context]
  );

  const handleSelectAnswer = (questionId: string, answerId: string) => () => {
    send({
      type: "SELECT_ANSWER",
      answerId,
      questionId,
    });
  };

  const isAnswerSelected = (
    exercise: Exercise,
    question: Question,
    answer: Answer
  ): boolean => {
    return state.context.answerList[exercise._id][question._id][answer._id];
  };

  const getExerciseScore = (exercise: Exercise): number => {
    const correctAnswers = exercise.questionsData?.filter(
      (q) =>
        q?.possibleAnswers &&
        q.possibleAnswers.some(
          (p) => p?.isCorrect && isAnswerSelected(exercise, q, p)
        )
    );
    return correctAnswers?.length ?? 0;
  };

  const hasExplanations = (exercise: Exercise): boolean => {
    return (
      !!exercise?.questionsData &&
      currentExercise.questionsData.some((q) => !!q?.explanation?.text)
    );
  };

  const isWaitingSelection = (exercise: Exercise): boolean => {
    return Object.entries(state.context.answerList[exercise._id]).some(
      (q) => !Object.entries(q[1]).some((a) => !!a[1])
    );
  };

  const handleHideExplanations = () => {
    send({ type: "HIDE_EXPLANATIONS" });
  };

  const handleShowExplanations = () => {
    send({ type: "SHOW_EXPLANATIONS" });
  };

  const getHtml = (
    value: string
  ): {
    __html: string;
  } => ({
    __html: value,
  });

  const saveProgress = () => {
    saveQuiz(data._id, state.context, isStarter);
    const lastBookmark = getLastBookmark(data.bookmark ?? [], isStarter);
    const exerciseScore = getExerciseScore(currentExercise as any);

    return updateBookmark({
      variables: {
        learningId: data?.learningId ?? "-1",
        id: data._id,
        type: isStarter ? BookmarkType.MockExam : BookmarkType.Standard,
        status:
          lastBookmark?.status === BookmarkStatus.Done ||
          state.context.currentExercise === state.context.exerciseList.length
            ? BookmarkStatus.Done
            : BookmarkStatus.InProgress,
        score: exerciseScore,
        currentQuestionId: currentExercise?.learningId ?? 0,
      },
    });
  };

  const handleNextExercise = () => {
    if (error) {
      saveProgress().then(() => send({ type: "NEXT" }));
    } else {
      send({ type: "NEXT" });
    }
  };

  useEffect(() => {
    if (state.context.finished && !state.context.finishCallbackCalled) {
      if (isStarter || isExpired || isDone) {
        updateBookmark({
          variables: {
            learningId: data?.learningId ?? "-1",
            id: data._id,
            type: isStarter ? BookmarkType.MockExam : BookmarkType.Standard,
            status: BookmarkStatus.Done,
            score: state.context.score ?? 0,
          },
        }).catch(() => {
          enqueueSnackbar(
            "Erreur lors de l'enregistrement de la progression. Veuillez contacter l'administrateur.",
            {
              variant: "error",
            }
          );
        });
      } else {
        const lastBookmark = getLastBookmark(data.bookmark ?? [], isStarter);
        if (lastBookmark?.status !== BookmarkStatus.Done) {
          const now = getTimestamp(new Date());
          updateBookmarkAndTime({
            variables: {
              id: data._id,
              type: isStarter ? BookmarkType.MockExam : BookmarkType.Standard,
              score: state.context.score ?? 0,
              userID: getUserId(),
              startTimestamp: now,
              endTimestamp: now + (data.estimatedTime ?? 0),
              categoryId: data.parentCategories?.[0]?.parent?.parent?._id,
            },
          }).catch(() => {
            enqueueSnackbar(
              "Erreur lors de l'enregistrement de la progression. Veuillez contacter l'administrateur.",
              {
                variant: "error",
              }
            );
          });
        }

        dispatch(addToCurrentYearTime(data.estimatedTime ?? 0));
      }
      deleteSavedQuiz(data._id, isStarter);
      onQuizEnd(state.context.score ?? 0);
    }
  }, [state.context.finished]);

  useEffect(() => {
    if (state.matches("exercise.showAnswer")) {
      saveProgress();
    } else if (state.matches("exercise.loadingAssets")) {
      onExerciseChange();
    } else if (state.matches("exercise.showExercise.waitingValidation")) {
      saveQuiz(data._id, state.context, isStarter);
    }
  }, [state.value]);

  useEffect(() => {
    if (error) {
      console.log(error);
      enqueueSnackbar(
        "Erreur lors de l'enregistrement de la progression. Veuillez réessayer.",
        {
          variant: "error",
        }
      );
    }
  }, [error]);

  return (
    <Container maxWidth="md" className={classes.container}>
      <div className={classes.quizContent} id={`quiz-${type}`}>
        {["exercise.showAnswer"].some(state.matches) && (
          <Dialog
            open={["exercise.showAnswer.showExplanations"].some(state.matches)}
            onClose={handleHideExplanations}
            className={classes.explanationModal}
            classes={{ paper: classes.explanationContent }}
          >
            <Typography className={classes.explanationTitle}>
              Explications
            </Typography>
            <div
              dangerouslySetInnerHTML={getHtml(
                currentExercise.questionsData
                  .map((q) =>
                    q?.explanation?.text
                      ? "<p>" + q.explanation.text + "</p>"
                      : ""
                  )
                  .join("")
              )}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleHideExplanations}
              className={classes.explanationBtn}
            >
              Fermer
            </Button>
          </Dialog>
        )}
        {["waiting", "initializeAnswers"].some(state.matches) && (
          <CircularProgress />
        )}
        {state.matches("exercise.loadingAssets") && (
          <Box display="flex" justifyContent="center" mt={4}>
            <Box maxWidth="50%">
              <CircularProgress />
            </Box>
          </Box>
        )}

        {["exercise.showExercise", "exercise.showAnswer"].some(
          state.matches
        ) && (
          <Box>
            {(type === Type.Analyse || type === Type.Categorisation) && (
              <h2
                style={{
                  margin: 0,
                  color:
                    type === Type.Analyse
                      ? colorCAC.purple
                      : Type.Categorisation
                      ? colorCAC.greenLight
                      : colorCAC.black,
                }}
              >
                CONSIGNE
              </h2>
            )}
            {currentExercise.text && (
              <Typography
                className="exerciseText"
                dangerouslySetInnerHTML={{ __html: currentExercise.text }}
              />
            )}
            <Box>
              {currentExercise.questionsData.map((question) => (
                <Box
                  key={`question-${question._id}`}
                  className="questionContainer"
                >
                  {question.text && (
                    <Typography
                      className="questionText"
                      dangerouslySetInnerHTML={{ __html: question.text }}
                    />
                  )}
                  <Box className="answers-container">
                    {question.possibleAnswers.map((answer) => (
                      <AnswerCard
                        key={answer._id}
                        text={answer?.text ?? ""}
                        correction={
                          state.matches("exercise.showAnswer")
                            ? answer.isCorrect
                            : undefined
                        }
                        selected={isAnswerSelected(
                          currentExercise as any,
                          question as any,
                          answer as any
                        )}
                        onClick={handleSelectAnswer(question._id, answer._id)}
                      />
                    ))}
                  </Box>
                </Box>
              ))}

              <Box
                display="flex"
                justifyContent="flex-end"
                position={
                  navigator.platform === "iPad" || "iPhone"
                    ? "fixed"
                    : "absolute"
                }
                bottom="39px"
                right="14%"
                zIndex="100"
              >
                {state.matches("exercise.showExercise") && (
                  <Button
                    onClick={() =>
                      !isWaitingSelection(currentExercise as any) &&
                      send({ type: "VALIDATE_ANSWER" })
                    }
                    className={classes.btn}
                  >
                    <div
                      className={`${classes.btnAction} ${
                        isWaitingSelection(currentExercise as any)
                          ? classes.btnValidateDisabled
                          : classes.btnValidate
                      }`}
                    />
                  </Button>
                )}
                {state.matches("exercise.showAnswer") &&
                  hasExplanations(currentExercise as any) && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="128px"
                    >
                      <Button
                        onClick={handleShowExplanations}
                        className={classes.btn}
                      >
                        <div
                          className={`${classes.btnAction} ${classes.btnExplanations}`}
                        />
                      </Button>
                      <Button
                        onClick={handleNextExercise}
                        className={classes.btn}
                      >
                        <div
                          className={`${classes.btnAction} ${classes.btnNext}`}
                        />
                      </Button>
                    </Box>
                  )}
                {state.matches("exercise.showAnswer") &&
                  !hasExplanations(currentExercise as any) && (
                    <Button
                      onClick={handleNextExercise}
                      className={classes.btn}
                    >
                      <div
                        className={`${classes.btnAction} ${classes.btnNext}`}
                      />
                    </Button>
                  )}
              </Box>
            </Box>
            <Paper
              elevation={3}
              style={{
                position:
                  navigator.platform === "iPad" || "iPhone"
                    ? "fixed"
                    : "absolute",
                bottom: 0,
                height: "70px",
                left: 0,
                right: 0,
                margin: "0",
              }}
            >
              {state.matches("exercise") && (
                <Container maxWidth="md" style={{ height: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="left"
                    height="100%"
                    justifyContent="center"
                    margin="0 0 0 48px"
                  >
                    <Typography className={classes.label}>
                      Question : {state.context.currentExercise + 1} /{" "}
                      {state.context.exerciseList.length}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={
                        ((state.context.currentExercise + 1) * 100) /
                        state.context.exerciseList.length
                      }
                      className={classes.progress}
                      classes={{ bar: classes.bar }}
                    />
                  </Box>
                </Container>
              )}
            </Paper>
          </Box>
        )}
      </div>
    </Container>
  );
};
