import {
  Image,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import {
  formattedDate,
} from "../../helpers/date-fomatter.helper";
import header from "./assets/header.png";
import logoRf from "./assets/Logo-RF.png";
import logoCac from "./assets/rf-e-learning-cac-logo.png";
import signature from "./assets/signature_responsable_mb.png";
import tampon from "./assets/tampon_organisme.png";
import { CertificateFragment } from "../../graphql";
import moment from "moment";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  mainSection: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    padding: "27px 27px",
    fontSize: 12,
    fontFamily: "Times-Roman",
  },
  section: {
    width: "100%",
  },
  halfSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    alignContent: "center",
  },
  container: {
    marginBottom: 10,
  },
  title: {
    fontWeight: "bold",
    marginBottom: 10,
  },
  listItem: {
    marginBottom: 10,
  },
  space: {
    marginBottom: 10, // 📌 Ajoute un vrai interligne
  },
  strong: {
    fontWeight: "bold", // Applique le gras aux éléments <strong>
  },
});

/**
 * CertificatePicker props
 */
interface ICertificateProps {
  certificate: CertificateFragment;
  fullName: string;
}

const decodeHtmlEntities = (html: string) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};


const getRawModuleDescription = (moduleDescription: string) => {

  const decodedDescription = decodeHtmlEntities(moduleDescription);


  return decodedDescription
    .replace(/<\/?p>/g, "\n") // Remplace les <p> par des sauts de ligne
    .replace(/<\/?strong>/g, "**") // Remplace les <strong> par **
    .replace(/<\/?ul>/g, "") // Enlève les balises <ul>
    .replace(/<\/li>/g, "") // Supprime uniquement les balises </li>
    .replace(/<li>/g, "") // Remplace <li> par un saut de ligne et un tiret
    .trim()
    .split("\n") // Découpe en lignes
    .filter((line) => line.trim() !== "") // Supprime les lignes vides
    .flatMap((line, index) => {
      // 🎯 Si la ligne contient du texte en gras
      if (line.includes("**")) {
        return [
          <Text key={`title-${index}`} style={styles.title}>
            {line.replace(/\*\*/g, "")}
          </Text>,
        ];
      } else {
        // 📌 Traitement des éléments de liste sans couper les mots composés
        return (
          <Text key={`item-${index}`} style={styles.listItem}>
            {line.trim()}
          </Text>
        );
      }
    });

};


// Create Document Component
const Certificate = ({ certificate, fullName }: ICertificateProps) => {

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={header} style={{ width: "100%", marginBottom: 20 }} />
        <View style={styles.halfSection}>
          <Image src={logoCac} style={{ width: 115 }} />
        </View>
        <View style={styles.halfSection}>
          <Image src={logoRf} style={{ width: 83 }} />
        </View>
        <View style={styles.mainSection}>
          <View style={styles.section}>
            <Text
              style={{
                marginTop: 36,
                marginBottom: 38,
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "center",
                color: "#18202f",
              }}
            >
              ATTESTATION DE FORMATION
            </Text>
            <View />
            <Text style={{ marginBottom: 32 }}>
              Je soussign&eacute;, Mathieu Brugière, Directeur Adjoint du
              département Formation de l&rsquo;organisme Revue Fiduciaire
              d&eacute;clar&eacute; en tant qu&rsquo;organisme formateur sous le
              n&deg; 11.75.32.96.075
            </Text>
            <Text style={{ marginBottom: 10 }}>
              certifie par la pr&eacute;sente que le participant :
            </Text>
            <Text style={{ marginBottom: 10 }}>
              Nom et pr&eacute;nom du participant : {fullName}
            </Text>
            <Text style={{ marginBottom: 10 }}>
              a bien suivi la formation &agrave; distance intitul&eacute;e :
            </Text>
            <Text style={{ marginBottom: 10 }}>
              RF e-Learning CAC - {certificate.title}
            </Text>

            <Text style={{ marginBottom: 34 }}>
              au {certificate?.elapsedTime?.endTimestamp
                ? moment(
                  certificate.elapsedTime.endTimestamp * 1000
                ).format("DD/MM/YYYY")
                : ""}
            </Text> */

            <Text style={{ marginBottom: 10 }}>
              Soit un nombre total d&rsquo;heures de temps de formation
              r&eacute;alis&eacute;s de{" "}
              {
                //! TODO recuper  le confirmated Time
                /* {getSecondsFormatted(certificate.confirmedTime, true)} portant sur */
              }
              les thèmes principaux suivants :
            </Text>

            <View style={styles.container}> {getRawModuleDescription(certificate.description ?? "")}</View>

            {certificate?.briefs?.[0]?.html && (
              <Text style={{ marginTop: 10 }}>

                - Dossier du mois :{" "}
                {decodeHtmlEntities(certificate.briefs[0].html.match(/<h1>(.*?)<\/h1>/)?.[1] || "")}

              </Text>
            )}

            <Text style={{ marginTop: 34, marginBottom: 34 }}>
              Attestation t&eacute;l&eacute;charg&eacute;e le{" "}
              {formattedDate(new Date())}
            </Text>
          </View>
          <View
            style={[
              styles.halfSection,
              { border: "1pt solid #555555", paddingTop: 3, paddingBottom: 7 },
            ]}
          >
            <Text style={{ width: "100%", fontSize: 9 }}>
              Cachet obligatoire de l&rsquo;organisme de formation
            </Text>
            <Image
              src={tampon}
              style={{ width: 90, marginLeft: 15, marginTop: 15 }}
            />
          </View>
          <View
            style={[
              styles.halfSection,
              {
                borderTop: "1pt solid #555555",
                borderRight: "1pt solid #555555",
                borderBottom: "1pt solid #555555",
                paddingTop: 3,
                paddingBottom: 7,
              },
            ]}
          >
            <Text style={{ width: "100%", fontSize: 9 }}>
              Signature du responsable de l&rsquo;organisme de formation
            </Text>
            <Image
              src={signature}
              style={{ width: 90, marginLeft: 15, marginTop: 15 }}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Certificate;
