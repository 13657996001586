// React
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
// Material ui
import { Box, Fab, Grid } from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// Libs
import Helmet from "react-helmet";
// Services
import NoResult from "../../features/NoResult/NoResult";
import SkeletonLoader from "../../features/SkeletonLoader/SkeletonLoader";
// Color
import { colorCAC } from "../../styles/color";
// GraphQl
import { Status, useModuleListLazyQuery } from "../../graphql";
import ModuleCard from "../../features/ModuleCard/ModuleCard";
import { Module } from "../../models/module.model";
import { mapModule } from "../../services/module.service";

import { getIsSuperAdmin } from "../../services/user.service";
import moment from "moment";

const PER_PAGE = 3;
/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 16,
      [theme.breakpoints.up("md")]: {
        paddingLeft: "10%",
        paddingRight: "10%",
      },
      "@supports (-webkit-touch-callout: none)": {
        marginBottom: 149,
      },
    },
    header: {
      position: "sticky",
      background: colorCAC.grayLight,
      width: "calc(100% + 20px)",
      zIndex: 3,
      top: 0,
      paddingTop: 10,
      marginTop: -10,
      paddingLeft: 10,
      marginLeft: -10,
      marginRight: 10,
    },
    title: {
      color: colorCAC.orange,
      fontWeight: "bold",
    },
    button: {
      color: "white",
      fontWeight: "bold",
      marginTop: theme.spacing(5),
      textTransform: "none",
    },
  })
);

/**
 * Modules props
 */
interface IModulesProps {
  isScrollAtBottom: boolean;
}

/**
 * Modules Component
 */
const Modules = (props: RouteComponentProps & IModulesProps) => {
  const { isScrollAtBottom } = props;

  /** super admin */
  const [isSuperAdmin] = useState(getIsSuperAdmin());

  const { history } = props;

  /** Classes  */
  const classes = useStyles();
  /** Theme */
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  /** loading state */
  const [loading, setLoading] = useState<boolean>(true);
  /** Modules list state */
  const [moduleList, setModuleList] = useState<any>();
  /** Pagination page state */
  const [page, setPage] = useState<number>(1);
  /** GraphQL */
  const [getModules, { data }] = useModuleListLazyQuery({
    errorPolicy: "none",
    fetchPolicy: "network-only",
  });
  /** pagination */
  const [hasMore, setHasMore] = useState<boolean>(true);

  /** Use effect */
  useEffect(() => {
    if (page) {
      setLoading(true);
      const variables = {
        page: page,
        per_page: PER_PAGE,
        ...(isSuperAdmin ? {} : { status: Status.Published }),
      };
      getModules({ variables });
    }
  }, [page, isSuperAdmin]);

  useEffect(() => {
    if (isScrollAtBottom && !loading && hasMore) {
      setPage(page + 1);
    }
  }, [isScrollAtBottom]);

  useEffect(() => {
    if (data?.categoriesV2?.data) {
      const children =
        data.categoriesV2.data.filter(
          (category) =>
            category.validityTimeRange?.start &&
            moment(category.validityTimeRange.start).isAfter(
              moment().subtract(37, "months")
            )
        ) ?? [];

      if (children.length < PER_PAGE) {
        setHasMore(false);
      }

      setModuleList([
        ...(moduleList ?? []),
        ...children.map((c) => mapModule(c)),
      ]);
      setLoading(false);
    }
  }, [data]);

  /**
   * Redirect to home page
   */
  const redirectToHome = (): void => {
    history.push({
      pathname: "/home",
    });
  };

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Tous mes modules - RF e-Learning CAC</title>
      </Helmet>
      <Grid
        container={true}
        spacing={3}
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.container}
      >
        {!loading && moduleList && moduleList.length === 0 && (
          <React.Fragment>
            <NoResult
              isCertificate={true}
              titleCustom=""
              subTitleCustom="Vous ne disposez pas encore de modules."
            />
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              onClick={() => redirectToHome()}
              className={classes.button}
            >
              Retour à la page d'accueil
            </Fab>
          </React.Fragment>
        )}
        {moduleList && moduleList.length > 0 && (
          <Grid item={true} xs={true}>
            <React.Fragment>
              <Grid
                container={true}
                direction="row"
                alignItems="center"
                justifyContent={isUpMd ? "flex-start" : "center"}
                className={classes.header}
              >
                <Grid item={true}>
                  <Box
                    marginBottom={1}
                    fontSize="h6.fontSize"
                    className={classes.title}
                  >
                    Tous mes modules
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container={true}
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                {moduleList.map((module: Module) => (
                  <ModuleCard key={module._id} module={module} />
                ))}
              </Grid>
            </React.Fragment>
          </Grid>
        )}
        {loading && (
          <React.Fragment>
            {(!moduleList || moduleList.length === 0) && (
              <Grid item={true} style={{ marginRight: isUpMd ? "auto" : 0 }}>
                <SkeletonLoader width={200} height={30} />
              </Grid>
            )}
            <Grid item={true}>
              <SkeletonLoader width={1280} height={210} />
            </Grid>
            <Grid item={true}>
              <SkeletonLoader width={1280} height={210} />
            </Grid>
            <Grid item={true}>
              <SkeletonLoader width={1280} height={210} />
            </Grid>
            <Grid item={true}>
              <SkeletonLoader width={1280} height={210} />
            </Grid>
            <Grid item={true}>
              <SkeletonLoader width={1280} height={210} />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default withRouter(React.memo(Modules));
