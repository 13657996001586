// React
import React, { useRef, useEffect } from "react";
// Material
import { Button, CircularProgress, Fab, Grid, Hidden } from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
// Lib
import { useSnackbar } from "notistack";
// Colors
import { colorCAC } from "../../styles/color";
// Service
import { isEdge } from "../../services/browser.service";
// Keycloak
import { RESPONSIVE } from "../../config/responsive";
import { CertificateFragment } from "../../graphql";
import Certificate from "../Certificate/Certificate";
import { formattedDate } from "../../helpers/date-fomatter.helper";
import { getPdf } from "../../services/utility.service";
import { User } from "../../models/user.model";

/**
 * Circular progress
 */
const ColorCircularProgress = withStyles({
  root: {
    color: "rgba(0, 0, 0, 0.26)",
    marginRight: 5,
  },
})(CircularProgress);

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /** buttonEnableSelectionContainer class relative to Certificate view */
    buttonEnableSelectionContainer: {
      backgroundColor: "white",
      boxShadow: "0 -1px 9px 0 rgba(0, 0, 0, 0.1)",
      position: "sticky",
      transform: "translate3d(0, 0, 0)",
      bottom: 4,
      padding: theme.spacing(2),
      /** remove grid's padding + container's padding */
      marginBottom: -theme.spacing(1) + -theme.spacing(2),
      marginTop: theme.spacing(5),
      marginLeft: -theme.spacing(2),
      marginRight: -theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        position: "fixed",
        width: "100%",
        paddingBottom: theme.spacing(2),
        textAlign: "center",
        left: "20px",
        height: "12%",
      },
      /* ----------- iPad 1, 2, Mini and Air ----------- */
      /* Portrait */
      [RESPONSIVE.IPAD_1_AND_2_MINI_AND_AIR.PORTRAIT]: {
        position: "fixed",
        right: 15,
        width: "63%",
        left: "auto",
      },
      /* ----------- iPad 3, 4 and Pro 9.7" ----------- */
      /* Portrait */
      [RESPONSIVE.IPAD_3_AND_4_AND_PRO_9.PORTRAIT]: {
        position: "fixed",
        right: 15,
        width: "63%",
        left: "auto",
      },
    },
    button: {
      color: "white",
      fontWeight: "bold",
      marginTop: theme.spacing(5),
      textTransform: "none",
    },
    buttonEnableSelection: {
      color: colorCAC.black,
      fontWeight: 600,
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        fontWeight: "normal",
        fontSize: 16,
        textAlign: "center",
        margin: "0 auto",
      },
    },
    stickyDownload: {
      position: "sticky",
      width: "fit-content",
      bottom: theme.spacing(5),
      margin: `${theme.spacing(2)}px auto`,
    },
    buttonDownload: {
      marginTop: 0,
      "&$buttonDownloadDisabled": {
        backgroundColor: "silver",
      },
    },
    buttonDownloadDisabled: {},
    buttonDisableSelection: {
      color: "#65747b",
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: 0,
      },
    },
  })
);

/**
 * CertificateDownloadCard props
 */
interface ICertificateDownloadCardProps {
  multipleSelectionEnabled: boolean;
  buttonDisabled: boolean;
  certificateList: CertificateFragment[];
  enableSelection(enable: boolean): void;
  user: User;
}

/**
 * CertificateDownloadCard component
 */
const CertificateDownloadCard = (props: ICertificateDownloadCardProps) => {
  /** Classes  */
  const classes = useStyles();
  /** Props */
  const {
    enableSelection,
    multipleSelectionEnabled,
    buttonDisabled,
    certificateList,
    user,
  } = props;
  /** use snackbar */
  const { enqueueSnackbar } = useSnackbar();
  /** loadingDownload */
  const [loadingDownload, setLoadingDownload] = React.useState(false);

  const downloadBtnRef = useRef<any>(null);

  const getCertificateList = async (certificates: CertificateFragment[]) => {
    if (loadingDownload) return;

    setLoadingDownload(true);

    try {
      for (const certificate of certificates) {
        const formattedStartDate = certificate.validityTimeRange?.start
          ? formattedDate(
              new Date(certificate.validityTimeRange?.start),
              "-",
              "mm/yyyy"
            )
          : "";

        const pdfName = `Attestation-RF-e-Learning-CAC-${formattedStartDate}-${user.firstName}-${user.lastName}-${certificate._id}`;

        try {
          await getPdf(
            <Certificate
              certificate={certificate}
              fullName={`${user.lastName} ${user.firstName}`}
            />,
            pdfName
          );
        } catch (e) {
          enqueueSnackbar(
            `Erreur lors de la génération de l'attestation pour ${certificate._id}`,
            { variant: "error" }
          );
        }
      }
    } catch (error) {
      enqueueSnackbar(
        "Une erreur générale est survenue pendant la génération des attestations",
        {
          variant: "error",
        }
      );
    } finally {
      setLoadingDownload(false);
    }
  };

  /**
   * download selection
   * @param certificateId
   */
  const downloadSelection = async () => {
    console.log("selection", certificateList);
    setLoadingDownload(true);

    await getCertificateList(certificateList);
  };

  /**
   * Use effect to fix sticky download btn for edge brownser
   */
  useEffect(() => {
    if (isEdge()) {
      const rand: number = Math.random();
      downloadBtnRef?.current?.style &&
        (downloadBtnRef.current.style.marginTop = rand + "em");
    }
  }, [buttonDisabled]);

  const downloadBtn = (
    <Fab
      variant="extended"
      size="medium"
      color="primary"
      onClick={() => downloadSelection()}
      classes={{
        root: `${classes.button} ${classes.buttonDownload}`,
        disabled: classes.buttonDownloadDisabled,
      }}
      disabled={buttonDisabled || loadingDownload}
    >
      {loadingDownload ? (
        <ColorCircularProgress size={17} thickness={5} />
      ) : null}
      Télécharger votre sélection
    </Fab>
  );

  const cancelBtn = (
    <Button
      className={classes.buttonDisableSelection}
      onClick={() => enableSelection(!multipleSelectionEnabled)}
    >
      Annuler
    </Button>
  );

  return (
    <>
      <Hidden mdUp={true}>
        <Grid
          container={true}
          item={true}
          direction="column"
          alignItems="center"
          className={classes.buttonEnableSelectionContainer}
        >
          {multipleSelectionEnabled ? (
            <>
              {downloadBtn}
              {cancelBtn}
            </>
          ) : (
            <Button
              className={classes.buttonEnableSelection}
              onClick={() => enableSelection(!multipleSelectionEnabled)}
            >
              Sélectionner plusieurs attestations
            </Button>
          )}
        </Grid>
      </Hidden>

      {multipleSelectionEnabled && (
        <Hidden smDown={true}>
          <>
            <Grid
              ref={downloadBtnRef}
              container={true}
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.stickyDownload}
            >
              {downloadBtn}
            </Grid>
            <Grid
              container={true}
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              {cancelBtn}
            </Grid>
          </>
        </Hidden>
      )}
    </>
  );
};

export default React.memo(CertificateDownloadCard);
